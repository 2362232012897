@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fdb9c6;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b96b85;
  border-radius: 10px;
}

.dela-gothic-one-regular {
  font-family: "Dela Gothic One", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.abel-regular {
  font-family: "Abel", sans-serif;
  font-weight: 400;
  font-style: normal;
}
