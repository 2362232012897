.adminContent {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "arial";
  background-color: black;
  height: 100%;
  width: 100%;
  min-height: 100vh;
}

.adminLoginForm {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 100px;
  border-radius: 25px;
  background-color: whitesmoke;
}

.adminLoginFormLabel {
  font-size: 20px;
}

.adminFormLoginBtn {
  margin-top: 40px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 10px;
  background-color: green;
  color: #fff;
  border: none;
  cursor: pointer;
}

.adminFormLoginBtn:hover {
  background-color: darkgreen;
}

.adminLoginFormInput {
  height: 40px;
  width: 200px;
  border-radius: 5px;
  border: none;
}

.adminMessage {
  background-color: antiquewhite;
  border-radius: 15px;
  padding: 25px;
  margin-top: 10px;
  width: 500px;
}

.adminRefreshBtn {
  height: 50px;
  border: none;
  background-color: #2b4f43;
  color: white;
  margin-top: 80px;
  border-radius: 15px;
  font-family: "EngraversGothic BT";
  font-size: 24px;
  width: 550px;
  cursor: pointer;
}

.adminRefreshBtn:hover {
  color: gray;
  text-decoration: none;
}

.adminDeleteBtn {
  width: 300px;
  height: 50px;
  border: none;
  border-radius: 15px;
  background-color: rgb(230, 23, 16);
  color: white;
  margin-top: 0px;
  font-family: "EngraversGothic BT";
  font-size: 24px;
  cursor: pointer;
}

.adminDeleteBtn:hover {
  color: gray;
  text-decoration: none;
}

.nomessagestext {
  color: white;
}
.noMessagesTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Button CSS */

.adminButtonsRow {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.admin-export-btn {
  height: 50px;
  border: none;
  background-color: #2f2f2f;
  color: white;
  border-radius: 15px;
  font-family: "EngraversGothic BT";
  font-size: 24px;
  width: 550px;
  cursor: pointer;
}
.admin-export-btn:hover {
  color: gray;
  text-decoration: none;
}

.admin-export-csv-btn {
  height: 50px;
  border: none;
  background-color: #075031;
  color: white;
  border-radius: 15px;
  font-family: "EngraversGothic BT";
  font-size: 24px;
  width: 550px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding-top: 5px;
}

.admin-export-csv-btn:hover {
  color: gray;
  text-decoration: none;
}